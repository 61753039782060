import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Table, Form, Pagination } from 'react-bootstrap'
import Layout from '../../Component/Layout';

import { useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DataTable, { createTheme } from 'react-data-table-component';
import moment from "moment/moment";
import axiosMain from '../../http/axios/axios_main';
const Userlist = () => {
    const datatoken = useSelector((state) => state.auth.value.accessToken);
    const [selectedRows, setSelectedRows] = useState([]);
    const columns = [
        {
            name: 'Date',
            selector: row => moment(row.createdAt).format('DD-MM-YYYY'),
            sortable: true,


        },
        {
            name: 'Email',
            selector: row => row.email,
            minWidth: '300px',
            sortable: true,


        },
        {
            name: 'Full Name',
            selector: row => row.fullName,
            sortable: true,



        },
        {
            name: 'UserName',
            selector: row => row.username,
            sortable: true,



        },
        {
            name: 'Is Seller',
            selector: row => row.isSeller,
            sortable: true,



            cell: row => <span>{row.isBecameSeller ? 'Yes' : 'No'}</span>
        },

        {
            name: 'Country Code',
            selector: row => row.countryCode,
            sortable: true,


            // cell: row => <span>{row.isBecameSeller ? 'Yes' : 'No'}</span>

        },
        {
            name: 'Phone',
            selector: row => row.phone,
            sortable: true,



        },
        {
            name: 'Is Indian Citizen',
            selector: row => row.isIndianCitizen,
            sortable: true,
            cell: row => <span>{row.isIndianCitizen ? 'Yes' : 'No'}</span>,




        },
        {
            name: 'Address',
            selector: row => row.address,
            sortable: true,



        },
        {
            name: 'City',
            selector: row => row.city,
            sortable: true,




        },
        {
            name: 'State',
            selector: row => row.state,
            sortable: true,



        },
        {
            name: 'Postal Code',
            selector: row => row.postalCode,
            sortable: true,



        },
        {
            name: 'Company Name',
            selector: row => row.companyName,
            sortable: true,



        },
        {
            name: 'Action',
            selector: row => row.status,
            minWidth: '150px',
            sortable: true,
            cell: row => (
                <button className={row.status ? "btn btn-danger" : "btn btn-success"} onClick={() => handleButtonClickAttachment(row)}>{row.status ? 'Disable' : 'Enable'}</button>

            )


        },
        // {
        //     name: 'WhatsApp',
        //     selector: row => row.whatsapp,
        //     sortable: true
        // },
        // {
        //     name: 'Skype ID',
        //     selector: row => row?.skypeId,
        //     sortable: true
        // },





    ];


    const handleButtonClickAttachment = async (data) => {

        try {
            const response = await axiosMain.post("/users/status", {
                id: data._id,
            }, {
                headers: {
                    authorization: `Bearer ${datatoken.token}`,
                },
            });
            console.log(response.data, '111statussssssssssss1111111111')

            if (response?.data?.status) {
                getQuestionData();
                toast.success(response?.data?.message)

            }
            console.log(response.data.data, '1111111111111')
        } catch (err) {
            console.log(err)
        }

    };

    useEffect(() => {
        getQuestionData()
    }, []);
    const [question, setquestion] = useState([])


    const getQuestionData = async () => {
        try {
            const response = await axiosMain.get("/users", {
                headers: {
                    authorization: `Bearer ${datatoken.token}`,
                },
            });
            if (response?.data?.status) {
                setquestion(response?.data?.data)
            } else {
                toast.warn(response?.data?.message)
            }
            console.log(response.data.data, '1111111111111')
        } catch (err) {
            console.log(err)
        }
    }
    console.log(question, 'call backkkkkkkkuserlisttkkkkkkkkkkk')
    return (
        <div>
            <Layout>
                <ToastContainer />
                <section className="outer-dash-section">

                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="dashboard-white-box">
                                    <div className="w-100">
                                        <h2 className="inner-page-heading mb-2"> User List</h2>

                                        <Row>
                                            <Col>
                                                <DataTable
                                                    // title="Requested Post"
                                                    columns={columns}
                                                    data={question}
                                                    pagination
                                                    selectableRows
                                                    onSelectedRowsChange={({ selectedRows }) => setSelectedRows(selectedRows)}


                                                />
                                            </Col>
                                        </Row>

                                    </div>
                                </div>
                            </Col>

                        </Row>
                    </Container>
                </section>
            </Layout>
        </div>
    )
}

export default Userlist