
import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Table, Form, Pagination } from 'react-bootstrap'
import Layout from '../../Component/Layout';

import { useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DataTable, { createTheme } from 'react-data-table-component';
import moment from "moment/moment";
import axiosMain from '../../http/axios/axios_main';


const Transactions = () => {

    const [selectedRows, setSelectedRows] = useState([]);

    const handleButtonClickAttachment = (rowData) => {
        // Do something when the button is clicked, you can access rowData here
        //   console.log(rowData,'rowDatarowDatarowData')
        window.open(rowData?.attachment, '_blank');

    };

    
     //pagination data table code
     const [totalCount, settotalCount] = useState(0)
     const [currentPage, setCurrentPage] = useState(1);
     const pageSize = 10; // Number of items per page
   
 
     const handlePageChange = (pageNumber) => {
         setCurrentPage(pageNumber);
       };
 
     //pagination code end
    //   const [expandedRows, setExpandedRows] = useState(null);
    const columns = [
        {
            name: 'Date',
            selector: row => moment(row.createdAt).format('DD-MM-YYYY'),
            sortable: true
        },
        {
            name: 'Title',
            selector: row => row.title,
            sortable: true
        },
        {
            name: 'Budget',
            selector: row => row.budget,
            sortable: true,
         cell: row => <span>{row.budget!='' ? row.budget : '--'}</span>

            

        },

        {
            name: 'Currency',
            selector: row => row.currency,
            sortable: true,

            // cell: row => <span>{row.description ? row.description : '--'}</span>
        },
        // {
        //     name: 'Category',
        //     minWidth: '600px',
        //     cell: row => (
        //         <>
        //             {row.categoryNames.map(item => (
        //                 <button key={item}>{item}</button>
        //             ))}
        //         </>
        //     )

        // },
        // {
        //     name: 'Attachment',
        //     cell: row => (
        //         <button onClick={() => handleButtonClickAttachment(row)}>Attachments</button>

        //     )
        // },
        {
            name: 'User Name',
            selector: row => row.userID?.username,
            sortable: true
        },
        {
            name: 'Email',
            selector: row => row?.userID?.email,
            minWidth: '200px',
            sortable: true
        },
        {
            name: 'Phone Number',
            selector: row => row.userID?.phone,
            minWidth: '100px',
            sortable: true
        },


        // {
        //     name: 'Status',
        //     selector: row => row.status,
        //     sortable: true
        // },
        // {
        //     name: 'Is Flexible',
        //     selector: row => row.isFlexible,
        //     sortable: true,
        //     cell: row => <span>{row.isFlexible === true ? 'Yes' : 'No'}</span>
        // },
        // {
        //     name: 'Schedule Timing',
        //     // selector: row => moment(row.deliveryTime)?.format('DD-MM-YYYY hh:mm'),
        //     sortable: true,

        //     cell: row => <span style={{ whiteSpace: 'pre-wrap', width: '400px' }}>{moment(row.deliveryTime)?.format('DD-MM-YYYY hh:mm')}</span>
        // },
        // {
        //     name: 'Horly Rate (₹)',
        //     selector: row => row.hourlyRate,
        //     sortable: true,
        // },
        {
            name: 'Actions',
            minWidth: '400px',
            cell: row => (

                <div>
                    {row?.status == 'pending' ? (
                        <>
                            <button className="btn btn-warning" style={{ margin: '10px' }} onClick={() => handleApproveReject(row, 'applied')}>APPROVE</button>
                            <button className="btn btn-danger" style={{ margin: '10px' }} onClick={() => handleApproveReject(row, 'rejected')}>REJECT</button>
                        </>
                    ) : <button className={row?.status == 'applied' ? 'btn btn-success' : 'btn btn-danger'} style={{ margin: '10px' }} >{row?.status.toUpperCase()}</button>
                    }

                </div>
            ),
            button: true
        }

    ];


    const [question, setquestion] = useState([])


    const datatoken = useSelector((state) => state.auth.value.accessToken);
    const getQuestionData = async () => {
        const skip = (currentPage - 1) * pageSize;
        try {
            const response = await axiosMain.get(`/request?skip=${skip}&limit=${pageSize}`, {
                headers: {
                    authorization: `Bearer ${datatoken.token}`,
                },
            });
            if (response?.data?.status) {
                setquestion(response?.data?.data)
                settotalCount(response?.data?.totalRecords)
            } else {
                toast.warn(response?.data?.message)
            }
            console.log(response.data.data, '1111111111111')
        } catch (err) {
            console.log(err)
        }
    }
    const handleApproveReject = async (data, status) => {
        try {
            const response = await axiosMain.patch("/request", {
                id: data?._id,
                status: status
            }, {
                headers: {
                    authorization: `Bearer ${datatoken.token}`,
                },
            });
            if (response?.data?.status) {
                toast.success(response?.data?.message);
                getQuestionData()
            }
            console.log(response.data, '111111ssssss1111111')
        } catch (err) {
            console.log(err)
        }
    }
    console.log(datatoken, 'datatokendatatoken')

    useEffect(() => {
        getQuestionData()
    }, [currentPage])



    
   

    // const conditionalRowStyles = [
    //     {
    //         when: row => row.title === 'yes',
    //         style: {
    //             backgroundColor: 'green',
    //             color: 'white',
    //         },
    //     },
    //     {
    //         when: row => row.title === 'no',
    //         style: {
    //             backgroundColor: 'red',
    //             color: 'white',
    //         },
    //     },
    // ];


    createTheme('solarized', {
        text: {
            primary: '#268bd2',
            secondary: '#2aa198',
        },
        background: {
            default: '#002b36',
        },
        context: {
            background: '#cb4b16',
            text: '#FFFFFF',
        },
        divider: {
            default: '#073642',
        },
        action: {
            button: 'rgba(0,0,0,.54)',
            hover: 'rgba(0,0,0,.08)',
            disabled: 'rgba(0,0,0,.12)',
        },
        rows: {
            default: {
                color: '#FFFFFF',
            },
            striped: {
                color: '#E1E1E1',
            },
            selected: {
                color: '#FFFFFF',
                background: '#268bd2',
            },
        },
        headCells: {
            style: {
                color: '#FFFFFF',
                background: '#002b36',
            },
        },
        cells: {
            style: {
                color: '#FFFFFF',
                background: '#002b36',
            },
        },
    }, 'dark');


    const [expandedRows, setExpandedRows] = useState([]);


    const handleRowExpand = (row) => {
        setExpandedRows([...expandedRows, row?._id]);
    };

    const handleRowCollapse = (row) => {
        setExpandedRows(expandedRows.filter((id) => id !== row?._id));
    };
    const ExpandableRow = ({ data }) => (
        <div>
            <p style={{ textAlign: 'center', fontWeight: 'bold' }}>Additional details:</p>
            <div className="table-box-new shadow-box-main" style={{ margin: '15px' }}>
                <div className="table-responsive">
                    <Table bordered >
                        <thead>
                            <tr>
                                <th>Description</th>
                                <th>Category</th>
                                <th>Status</th>
                                <th>Is Flexible</th>
                                <th>Schedule Timing</th>
                                <th>Hourly Rate</th>
                                <th>Attachments</th>

                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>{data?.description}</td>
                                <td>{data?.categoryNames.map(item => (
                                    <button style={{ margin: '10px' }} className="btn btn-secondary" key={item}>{item}</button>
                                ))}</td>
                                <td>{data?.status.toUpperCase()}</td>
                                <td>{data?.isFlexible ? 'Yes' : 'No'}</td>
                                <td>{moment(data?.deliveryTime)?.format('DD-MM-YYYY hh:mm')}</td>
                                <td>{data?.hourlyRate}</td>
                                <td> <button style={{ margin: '10px' }} className="btn btn-secondary" onClick={() => handleButtonClickAttachment(data)}>Attachments</button></td>
                            </tr>


                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
    );


    // const handleRowToggle = (row) => {
    //     const newExpandedRows = { ...expandedRows };
    //     newExpandedRows[row._id] = !expandedRows[row._id];
    //     setExpandedRows(newExpandedRows);
    //   };
    return (
        <>
            <Layout>
                <ToastContainer />
                <section className="outer-dash-section">

                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="dashboard-white-box">
                                    <div className="w-100">
                                        <h2 className="inner-page-heading mb-2"> Requests Received</h2>

                                        <Row>
                                            <Col>
                                                <DataTable
                                                    // title="Requested Post"
                                                    columns={columns}
                                                    data={question}
                                                    pagination
                                                    selectableRows
                                                    onSelectedRowsChange={({ selectedRows }) => setSelectedRows(selectedRows)}
                                                    // theme="solarized"
                                                    // conditionalRowStyles={conditionalRowStyles}


                                                    expandableRows
                                                    expandOnRowClicked
                                                    onRowExpand={handleRowExpand}
                                                    onRowCollapse={handleRowCollapse}
                                                    expandableRowsComponent={ExpandableRow}


                                                    // expandableRowExpanded={row => expandedRows[row._id]}
                                                    // onRowExpandToggled={handleRowToggle}
                                                    //pagination
                                                    paginationServer
                                                    paginationPerPage={pageSize} // Number of items per page for the DataTable component
                                                    paginationRowsPerPageOptions={[pageSize]} // Limit options to only the current page size
                                                    paginationTotalRows={totalCount}
                                                    onChangePage={handlePageChange}
                                                //pagination

                                                />
                                            </Col>
                                        </Row>

                                    </div>
                                </div>
                            </Col>

                        </Row>
                    </Container>
                </section>
            </Layout>
        </>
    )
}

export default Transactions