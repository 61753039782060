import { Navigate, useLocation } from 'react-router-dom';
import useAuth from "../../hooks/useAuth";
const PrivateRoute = ({ children }) => {
  // alert('dfrrrrrrrrrr')
  // const user = JSON.parse(localStorage.getItem('user')) || null;
  const { user, isAuthenticated } = useAuth();
  // const { pathname } = useLocation();
  // alert(user.email)
  const location = useLocation();
  return user && isAuthenticated ? (

    children
  ) : (
    <Navigate to='/' state={{ from: location }}  replace />
  )

}

export default PrivateRoute


// import React from "react";
// import { Navigate, useLocation } from "react-router-dom";
// import useAuth from "../../hooks/useAuth";

// const Protected = ({ children }) => {
//   const { user, isAuthenticated } = useAuth();
//   const location = useLocation();
//   if (user && isAuthenticated) {
//     return children;
//   } else {
//     return <Navigate to="/login" state={{ from: location }} />;
//   }
// };

// export default Protected;
