// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// reportWebVitals();


import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { persistStore } from "redux-persist";
import store from './store/store';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
import HttpsRedirect from 'react-https-redirect';
// import { BrowserRouter } from 'react-router-dom';




let persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
    
      <PersistGate loading={null} persistor={persistor}>
        {/*<HttpsRedirect> */}

          <App />
          
       {/* </HttpsRedirect> */}
      </PersistGate>
    
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();