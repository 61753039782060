import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route,
  Redirect,
  Navigate,
} from "react-router-dom";
import Login from "./Pages/Login"
import Register from "./Pages/Register";
import Verify_account from "./Pages/Verify_account";
import Otp_verification from "./Pages/Otp_verification";
import Forgot_password from "./Pages/Forgot_password";
import Dashboard from "./Pages/Dashboard/Dashboard";
import Transactions from './Pages/Dashboard/Transactions';
import Profile from './Pages/Dashboard/Profile';
import Requestedcalls from './Pages/Dashboard/Requestedcalls';
import "react-toastify/dist/ReactToastify.css";
import PrivateRoute from './Component/PrivateRoute/PrivateRoute';
import Userlist from './Pages/Dashboard/Userlist';
import Giglist from './Pages/Dashboard/Giglist';
function App() {
  return (
    <>
      
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/Register" element={<Register />} />
          <Route path="/Verify_account" element={<Verify_account />} />
          <Route path="/Otp_verification" element={<Otp_verification />} />
          <Route path="/Forgot_password" element={<Forgot_password />} />
          <Route path="/Dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
          <Route path="/post-request" element={<PrivateRoute><Transactions /></PrivateRoute>} />
          <Route path="/Profile" element={<PrivateRoute><Profile /></PrivateRoute>} />
          <Route path="/requested-calls" element={<PrivateRoute><Requestedcalls /></PrivateRoute>} />
          <Route path="/user-list" element={<PrivateRoute><Userlist /></PrivateRoute>} />
          <Route path="/gig-list" element={<PrivateRoute><Giglist /></PrivateRoute>} />

        </Routes>
      </BrowserRouter>

    </>

  );
}

export default App;
