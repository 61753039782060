import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Table, Form, Pagination } from 'react-bootstrap'
import Layout from '../../Component/Layout';

import { useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DataTable, { createTheme } from 'react-data-table-component';
import moment from "moment/moment";
import axiosMain from '../../http/axios/axios_main';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css'; // Import the styles

const Requestedcalls = () => {
    const datatoken = useSelector((state) => state.auth.value.accessToken);
    const [selectedRows, setSelectedRows] = useState([]);
    const columns = [
        {
            name: 'Date',
            selector: row => moment(row.createdAt).format('DD-MM-YYYY'),
            sortable: true
        },
        {
            name: 'Email',
            selector: row => row.email,
            minWidth: '300px',
            sortable: true
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,

        },

        {
            name: 'Phone',
            // selector: row => row.phone,
            minWidth: '200px',
            sortable: true,
            cell: row => <p>  <IntlTelInput
                defaultValue={`+${row.phone}`}
                containerClassName="intl-tel-input"
                inputClassName="form-control"
                disabled={true}
                geoIpLookup={function(callback) {
                    fetch('https://ipapi.co/json/')
                      .then(response => response.json())
                      .then(data => {
                        callback(data.country_code);
                      });
                  }}
            /></p>


        },
        {
            name: 'WhatsApp',
            // selector: row => row.whatsapp,
            sortable: true,
            minWidth: '200px',
            sortable: true,
            cell: row => <p>  <IntlTelInput
                defaultValue={`+${row.whatsapp}`}
                containerClassName="intl-tel-input"
                inputClassName="form-control"
                disabled={true}
                geoIpLookup={function(callback) {
                    fetch('https://ipapi.co/json/')
                      .then(response => response.json())
                      .then(data => {
                        callback(data.country_code);
                      });
                  }}
            /></p>
        },
        {
            name: 'Skype ID',
            selector: row => row?.skypeId,
            sortable: true,
            cell: row => <span>{row.skypeId!='' ? row.skypeId : '--'}</span>

        },
        {
            name: 'Telegram ID',
            selector: row => row?.telegram,
            sortable: true,
            cell: row => <span>{row.telegram!='' ? row.telegram : '--'}</span>

        },





    ];

    useEffect(() => {
        getQuestionData()
    }, []);
    const [question, setquestion] = useState([])


    const getQuestionData = async () => {
        try {
            const response = await axiosMain.get("/contactus", {
                headers: {
                    authorization: `Bearer ${datatoken.token}`,
                },
            });
            if (response?.data?.status) {
                setquestion(response?.data?.data)
            } else {
                toast.warn(response?.data?.message)
            }
            console.log(response.data.data, '1111111111111')
        } catch (err) {
            console.log(err)
        }
    }
    console.log(question, 'call backkkkkkkkkkkkkkkkkkk')
    return (
        <div>
            <Layout>
                <ToastContainer />
                <section className="outer-dash-section">

                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="dashboard-white-box">
                                    <div className="w-100">
                                        <h2 className="inner-page-heading mb-2"> Requested Callback</h2>

                                        <Row>
                                            <Col>
                                                <DataTable
                                                    // title="Requested Post"
                                                    columns={columns}
                                                    data={question}
                                                    pagination
                                                    selectableRows
                                                    onSelectedRowsChange={({ selectedRows }) => setSelectedRows(selectedRows)}


                                                />
                                            </Col>
                                        </Row>

                                    </div>
                                </div>
                            </Col>

                        </Row>
                    </Container>
                </section>
            </Layout>
        </div>
    )
}

export default Requestedcalls