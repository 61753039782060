import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import Layout from "../Component/Loginheader";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import axiosMain from './../http/axios/axios_main';
import { login } from "../reducer/authReducers";
import { ToastContainer, toast } from "react-toastify";
export default function Sign_in() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formError, setFormError] = useState("");
  const [inputdata, setInputdata] = useState({
    email: "",
    password: "",
  });
  const formdata = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputdata({ ...inputdata, [name]: value });
  };

  const HandleSubmit = async (e) => {
    let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");

    if (!inputdata.email) {
      setFormError("Please Enter Email");
    }
    //  else if (!regex.test(inputdata.email)) {
    //   setFormError("Please Enter valid email");
    // } 
    else if (!inputdata.password) {
      setFormError("Please Enter Password ");
      // toast.warn("Please Enter Password")
    } else {
      setFormError("");
      try {

        const payload={
          username:inputdata.email,
          password:inputdata.password
        }
        // const response = await axios.post("https://api.medyseva.com/api/vle/login", inputdata);

        const response = await axiosMain.post("/auth/admin", payload);

        console.log(response, "finelogin");
        if (!(response?.data.error)) {
          // toast.success(response?.data.message);
          // dispatch(setLoginAddress(response.data.user._id));
          toast.success("Admin Successfully Logged in");
          dispatch(
            login({
              user: response.data.user,
              isAuthenticated: true,
              accessToken:{
                token:response.data.token
              }
              
            })
          );
         setTimeout(() => {
          navigate("/Dashboard");
         }, 2000);
        }else{
            toast.error("Invalid Credentials");

        }
        setInputdata({
          email: "",
          password: "",
        });
      } catch (error) {
        toast.error("Invalid Credentials");
        console.log(error);
      }
    }
  };
  return (
    <>
      <Layout />
      <ToastContainer />
      <section className="signup-sec" >
        <Container>
          <Row>
            <Col lg={5} className="m-auto">
              <div className="outer-bg-signup">
                 <h3> Welcome </h3>
                <h5>Please login to continue </h5>

                <Form.Group className="mb-3">

                  <Form.Control type="email"  placeholder="Email"
                    onChange={formdata}
                    name="email"
                    value={inputdata.email} />
                </Form.Group>

                <Form.Group className="mb-3">
                      <InputGroup className="mb-3">
                        <Form.Control  placeholder="Password"
                           onChange={formdata}
                           value={inputdata.password}
                           name="password"
                          />
                        <InputGroup.Text><i class="fa fa-eye"></i></InputGroup.Text>
                      </InputGroup>
                    </Form.Group>

                    <div>
                      <button className="common-btn w-100 m-0" onClick={() => {
                        HandleSubmit();
                      }}>Login</button>
                    </div>

                  
                    <div className="outer-forgot-pass-link">
                      {/* <a href="/Forgot_password" className="forgot-link">Forgot Password?</a> */}
                      {/* <p className="dont-have-acc">Don't have an account? <a href="/Register">Register</a></p> */}
                    </div>
               
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}
