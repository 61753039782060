import { createSlice } from "@reduxjs/toolkit";

// const walletAddress = localStorage.getItem("walletAddress");
const authSlice = createSlice({
    name: "Auth",
    initialState: {
        // walletAddress: "",
        value: { user: {}, isAuthenticated: false,accessToken:{} },




        // walletCreated: false,
        // accountVerification: "",
        // routeAccess: false,
        // isTimerModalClosed: false,
    },
    reducers: {
        // closeTimerModal: (state) => ({ ...state, isTimerModalClosed: true }),
        // giveRouteAccess: (state) => {
        //   return { ...state, routeAccess: true };
        // },
        // revokeRouteAccess: (state) => {
        //   return { ...state, routeAccess: false };
        // },

        signup_data: (state, action) => {

            return {
                ...state,
                value: {
                    newUser: action.payload.newUser,
                },
                // walletCreated: action.payload.walletCreated,
            };
        },

        login: (state, action) => {

            
            return {
                ...state,
                value: {
                    user: action.payload.user,
                    isAuthenticated: action.payload.isAuthenticated,
                    accessToken: action.payload.accessToken,

                },
               
            };

            
        },
        logout: (state) => {
            localStorage.clear();
            return {
                ...state,
                walletAddress: "",
                value: { user: {}, isAuthenticated: false ,accessToken:{}},
                // walletCreated: false,
            };
        },
        agoraids: (state,action) => {
            localStorage.setItem("agoraaddress", JSON.stringify(action.payload.user));
            

            return {
                ...state,
                agoravalue: {
                    agoravalue: action.payload.user,
                

                },
            };
        },
        setWalletAddress: (state, action) => {
            localStorage.setItem("walletAddress", action.payload);
            return { ...state, walletAddress: action.payload };
        },
        setLoginAddress: (state, action) => {
            localStorage.setItem("loginAddress", action.payload);
            return { ...state, loginAddress: action.payload };
        },
    },
});

export const {
    login,
    agoraids,
    logout,
    signup_data,
    setWalletAddress,
    setLoginAddress,
    

} = authSlice.actions;
export default authSlice.reducer;
