import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Table, Form, Pagination } from 'react-bootstrap'
import Layout from '../../Component/Layout';

import { useNavigate } from "react-router";
import { toast, ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import DataTable, { createTheme } from 'react-data-table-component';
import moment from "moment/moment";
import axiosMain from '../../http/axios/axios_main';
const Giglist = () => {
    const datatoken = useSelector((state) => state.auth.value.accessToken);
    const [selectedRows, setSelectedRows] = useState([]);
    const columns = [
        {
            name: 'Date',
            selector: row => moment(row.createdAt).format('DD-MM-YYYY'),
            sortable: true,
            minWidth: '20px',
        },
        {
            name: 'Title',
            selector: row => row.title,
            minWidth: '20px',
            sortable: true,
        },
        {
            name: 'Username',
            selector: row => row?.userID?.username,
            sortable: true,
        },
        {
            name: 'Category',
            selector: row => row.category,
            sortable: true,
        },
        {
            name: 'Delivery Time',
            selector: row => row.deliveryTime,
            sortable: true,
        },
        {
            name: 'Description',
            selector: row => row.description,
            sortable: true,



            // cell: row => <span>{row.isBecameSeller ? 'Yes' : 'No'}</span>
        },

         {
            name: 'Features',
            minWidth: '300px',
            cell: row => (
                <>
                    {row.features.map(item => (
                        <button key={item}>{item}</button>
                    ))}
                </>
            )

        },
        {
            name: 'Price',
            selector: row => row.price,
            sortable: true,



        },
        {
            name: 'Revision Number',
            selector: row => row.revisionNumber,
            sortable: true,
        },
        {
            name: 'Short Description',
            selector: row => row.shortDesc,
            sortable: true,

        },
        {
            name: 'Short Title',
            selector: row => row.shortTitle,
            sortable: true,
        },
        {
            name: 'Star Number',
            selector: row => row.starNumber,
            sortable: true,



        },
      
        // {
        //     name: 'Company Name',
        //     selector: row => row.companyName,
        //     sortable: true,



        // },
        {
            name: 'Action',
            selector: row => row.status,
            minWidth: '150px',
            sortable: true,
            cell: row => (
                <button className={row.adminStatus ? "btn btn-danger" : "btn btn-success"} onClick={() => handleButtonClickAttachment(row)}>{row.adminStatus ? 'Disable' : 'Enable'}</button>

            )


        },
        // {
        //     name: 'WhatsApp',
        //     selector: row => row.whatsapp,
        //     sortable: true
        // },
        // {
        //     name: 'Skype ID',
        //     selector: row => row?.skypeId,
        //     sortable: true
        // },





    ];


    const handleButtonClickAttachment = async (data) => {
        // console.log(data,'giggggid')

        try {
            const response = await axiosMain.get(`/gigs/status/${data?._id}`,{
                headers: {
                    authorization: `Bearer ${datatoken.token}`,
                },
            });
            console.log(response.data, '111statussssssssssss1111111111')

            if (response?.data?.status) {
                getQuestionData();
                toast.success(response?.data?.message)
                // alert(response?.data?.message)

            }
            console.log(response.data.data, '1111111111111')
        } catch (err) {
            console.log(err)
        }

    };


    //pagination data table code
    const [totalCount, settotalCount] = useState(0)
    const [currentPage, setCurrentPage] = useState(1);
    const pageSize = 10; // Number of items per page
  

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
      };

    //pagination code end
    useEffect(() => {
        getQuestionData()
    }, [currentPage]);
    const [question, setquestion] = useState([])


    const getQuestionData = async () => {
        const skip = (currentPage - 1) * pageSize;
        try {
            const response = await axiosMain.get(`/gigs?skip=${skip}&limit=${pageSize}`, {
                headers: {
                    authorization: `Bearer ${datatoken.token}`,
                },
            });
            if (response?.data?.status) {
                setquestion(response?.data?.data)
                settotalCount(response?.data?.totalRecords?.count)
            } else {
                toast.warn(response?.data?.message)
            }
            console.log(response.data.data, '1111111111111')
        } catch (err) {
            console.log(err)
        }
    }
    console.log(question, 'call backkkkkkkkuserlisttkkkkkkkkkkk')
    return (
        <div>
            <Layout>
                {/* <ToastContainer   position="top-left"/> */}
                <section className="outer-dash-section">

                    <Container>
                        <Row>
                            <Col lg={12}>
                                <div className="dashboard-white-box">
                                    <div className="w-100">
                                        <h2 className="inner-page-heading mb-2"> Gig List</h2>

                                        <Row>
                                            <Col>
                                                <DataTable
                                                    // title="Requested Post"
                                                    columns={columns}
                                                    data={question}
                                                    pagination
                                                    selectableRows
                                                    onSelectedRowsChange={({ selectedRows }) => setSelectedRows(selectedRows)}
                                                     //pagination
                                                     paginationServer
                                                     paginationPerPage={pageSize} // Number of items per page for the DataTable component
                                                     paginationRowsPerPageOptions={[pageSize]} // Limit options to only the current page size
                                                     paginationTotalRows={totalCount}
                                                     onChangePage={handlePageChange}
                                                     //pagination

                                                />
                                            </Col>
                                        </Row>

                                    </div>
                                </div>
                            </Col>

                        </Row>
                    </Container>
                </section>
            </Layout>
        </div>
    )
}

export default Giglist